import React from "react"
import HeaderStyles from "../styles/header.module.scss"
import { Container, Row } from "./elements"

const Header = () => (
  <header className={HeaderStyles.menu}>
    <Container>
      <Row>
        <ul>
          <li>Item 1</li>
          <li>Item 2</li>
          <li>Item 3</li>
          <li>Item 4</li>
        </ul>
      </Row>
    </Container>
  </header>
)

export default Header

